<!-- eslint-disable vue/no-v-html -->
<template>
  <section>
    <b-modal
      ref="welcome"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="md"
      title="Painel de uso"
    >
      <div class="text-center">
        <feather-icon
          icon="CheckCircleIcon"
          size="70"
          class="text-success mt-1 mb-1"
        />
      </div>

      <h1>Bom dia, {{ userData.first_name }}!</h1>
      <p>
        Seja bem-vindo(a) à sua área de trabalho.
      </p>
      <p>
        Acompanhe abaixo um resumo das suas principais atividades no seu dia anterior de trabalho:
      </p>
      <p
        v-html="welcomeText"
      />
      <p v-if="showDownloadReportButton">
        O relatório do mês de {{ monthFromReport }} já está disponível:
        <b-button
          size="sm"
          :disabled="isDownloading"
          @click="downloadReport"
        >
          <feather-icon
            v-if="!isDownloading"
            icon="DownloadIcon"
            size="12"
          />
          <b-spinner
            v-else
            small
          />
          Baixar relatório
        </b-button>
      </p>
      <p>
        Tenha um ótimo dia de trabalho! <font-awesome-icon
          :icon="['fas', 'heart']"
          flip="horizontal"
          size="1x"
          class="text-info"
        />
      </p>

      <div class="text-center">
        <b-button
          class="text-center mb-1"
          variant="primary"
          :disabled="isLoading || isLoading"
          @click="$refs.welcome.hide()"
        >
          Fechar
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
/* eslint-disable global-require */
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { months } from '@/utils/options'
import LocalStorageService from '@/services/localStorageService'
import DashboardService from '@/services/dashboardService'
import UserService from '@/services/userService'
import downloadFile from '@/mixins/downloadFile'

export default {
  components: {
    BSpinner,
    BButton,
  },
  mixins: [
    downloadFile,
  ],
  props: {
    permissionType: {
      type: Object,
      default: null,
    },
    userData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userService: null,
      isLoading: true,
      resultWelcome: null,
      localStorageService: null,
      dashboardService: null,
      isDownloading: false,
      managers: null,
      months,
    }
  },
  computed: {
    monthFromReport() {
      const current = new Date()
      let lastMonth = current.getMonth() - 1
      lastMonth = lastMonth < 0 ? 11 : 0
      return this.months[lastMonth].text
    },

    welcomeText() {
      let openProfiles = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
      let confirmedPhones = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
      let confirmedEmails = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
      let saveProfiles = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
      let emailSent = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
      let workedCards = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'

      if (!this.isLoading) {
        workedCards = this.resultWelcome.welcome.worked_cards
      }

      if (!this.isLoading) {
        openProfiles = this.resultWelcome.welcome.open_profile ?? 0
        confirmedPhones = this.resultWelcome.welcome.confirmed_phones ?? 0
        confirmedEmails = this.resultWelcome.welcome.confirmed_emails ?? 0
        saveProfiles = this.resultWelcome.welcome.save_profile ?? 0
        emailSent = this.resultWelcome.welcome.sent_email ?? 0
      }

      const defaultRet = `Cards movimentados no kanban: <strong>${workedCards}</strong><br>
 Perfis abertos: <strong>${openProfiles}</strong><br>
 Telefones confirmados: <strong>${confirmedPhones}</strong><br>
 Emails confirmados: <strong>${confirmedEmails}</strong><br>
 Atualizações feitas: <strong>${saveProfiles}</strong>`

      if (this.userData && this.userData.permission_type === 'success') {
        let kanbanSchedules = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let kanbanOnboardings = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let kanbanFollowTour = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let kanbanTransitions = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let kanbanNewSubscriber = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let kanbanFollowGc = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let kanbanChurn = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'

        if (!this.isLoading) {
          kanbanSchedules = this.resultWelcome.welcome.kanban_schedules ?? 0
          kanbanOnboardings = this.resultWelcome.welcome.kanban_onboardings ?? 0
          kanbanFollowTour = this.resultWelcome.welcome.kanban_follow_tour ?? 0
          kanbanTransitions = this.resultWelcome.welcome.kanban_transitions ?? 0
          kanbanNewSubscriber = this.resultWelcome.welcome.kanban_new_subscriber ?? 0
          kanbanFollowGc = this.resultWelcome.welcome.kanban_follow_gc ?? 0
          kanbanChurn = this.resultWelcome.welcome.kanban_churn ?? 0
        }

        return `${defaultRet}<br>
 Tour agendados: <strong>${kanbanSchedules}</strong><br>
 Tours Guiados realizados: <strong>${kanbanOnboardings}</strong><br>
 Follow Onboarding: <strong>${kanbanFollowTour}</strong><br>
 Transições realizadas: <strong>${kanbanTransitions}</strong><br>
 Apresentações GC: <strong>${kanbanNewSubscriber}</strong><br>
 Follow GC: <strong>${kanbanFollowGc}</strong><br>
 Mitigação de Churn: <strong>${kanbanChurn}</strong>`
      }

      if (this.userData && this.userData.permission_type === 'sales') {
        let sentPaymentLink = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        if (!this.isLoading) {
          sentPaymentLink = this.resultWelcome.welcome.sent_payment_link ?? 0
        }

        return `${defaultRet}<br>
 Links de pagamento enviados: <strong>${sentPaymentLink}</strong>`
      }

      if (this.userData && ['service_qualification', 'management_processes'].includes(this.userData.permission_type)) {
        let finishedChats = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'
        let registrationApproved = '<span aria-hidden="true" class="spinner-border spinner-border-sm"><!----></span>'

        if (!this.isLoading) {
          finishedChats = this.resultWelcome.welcome.finished_chats ?? 0
          registrationApproved = this.resultWelcome.welcome.registration_approved ?? 0
        }

        return `${defaultRet}<br>
 Chats Finalizados: <strong>${finishedChats}</strong><br>
 Cadastros aprovados: <strong>${registrationApproved}</strong>`
      }

      return `${defaultRet}<br>
 E-mail enviados: <strong>${emailSent}</strong>`
    },
  },

  async created() {
    this.localStorageService = new LocalStorageService()
    this.dashboardService = new DashboardService()
    this.userService = new UserService()

    this.managers = await this.userService.getAndStoreManagers()

    this.$nextTick(() => {
      this.showWelcome()
    })
  },

  methods: {
    downloadReport() {
      this.isDownloading = true
      const current = new Date()
      const month = this.months[current.getMonth() === 0 ? 11 : current.getMonth() - 1].text
      const manager = this.managers.find(f => (f.id === this.permissionType.id))
      return this.dashboardService.downloadReport(this.permissionType.id, this.permissionType.role).then(response => {
        this.isDownloading = false
        this.openModalToSaveFile(response, `Relatório ${manager.name} - ${month}.pdf`)
      })
    },

    showDownloadReportButton() {
      const current = new Date()
      if (current.getDate() < 7) {
        return true
      }

      return false
    },

    showModal() {
      this.isLoading = true
      this.dashboardService.welcome(this.userData.permission_type, this.userData.id).then(response => {
        if (response.status === 200) {
          this.resultWelcome = response.data.data
        }
      }).finally(() => {
        this.isLoading = false
      })

      this.$refs.welcome.show()
    },

    showWelcome() {
      let lastWelcome = LocalStorageService.getString(LocalStorageService.dashboardModalWelcomeAt)
      if (lastWelcome !== null) {
        lastWelcome = new Date(lastWelcome)
      }
      let now = new Date()
      now = new Date(`${now.toISOString().substring(0, 10)} 00:00:00`)

      if ((lastWelcome === null || lastWelcome < now) && this.userData.permission_type !== 'root') {
        LocalStorageService.setString(LocalStorageService.dashboardModalWelcomeAt, `${now.toISOString().substring(0, 10)} 00:00:00`)
        this.showModal()
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
